@mixin screen($breakpoint) {
  @if $breakpoint == laptop {
    @media only screen and (min-width: $screen-laptop) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (min-width: $screen-tablet) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (min-width: $screen-mobile) {
      @content;
    }
  }
}

@mixin font-p3 {
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
}

@mixin font-p2 {
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
}

@mixin font-p1 {
  font-size: 18px;
  line-height: 150%;
  font-weight: 600;
}

@mixin font-p0 {
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  @include screen(tablet) {
    font-size: 18px;
  }
}

@mixin font-text3 {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  font-weight: 600;
}

@mixin font-text4 {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  font-weight: 400;
}

@mixin font-text2 {
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.02em;
  font-weight: 600;
  @include screen(laptop) {
    font-size: 16px;
  }
}

@mixin font-text2-sm {
  font-size: 14px;
  line-height: 140%;
  font-weight: 400;
}

@mixin font-text1 {
  font-size: 20px;
  line-height: 130%;
  font-weight: 700;
}

@mixin font-text {
  font-size: 24px;
  line-height: 130%;
  font-weight: 700;
}

@mixin font-text-medium-sm {
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
}

@mixin font-text-medium {
  font-size: 24px;
  line-height: 130%;
  font-weight: 500;
}

@mixin font-text-semibold {
  font-size: 24px;
  line-height: 130%;
  font-weight: 600;
}

@mixin font-h3 {
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.02em;
  font-weight: 700;
}

@mixin font-h2 {
  font-size: 32px;
  line-height: 120%;
  font-weight: 700;
  @include screen(tablet) {
    font-size: 56px;
  }
}

@mixin font-h1 {
  font-size: 36px;
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 120%;
  @include screen(tablet) {
    font-size: 56px;
  }
}