* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

textarea,
input,
button,
select,
a {
  outline: none;
  border-radius: 0;

  font-weight: 400;
  font-family: "Gilroy", Arial, sans-serif;
  touch-action: manipulation;
}

html,
body {
  height: 100%;

  font-family: "Gilroy", Arial, sans-serif;
  color: $text;
  @include font-text4;
}

.render {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  width: 100%;
  height: 100%;

  background-color: $white;
}

.wrapper {
  margin: 0 auto;
  padding: 0 #{$container-padding};
  width: 100%;
  max-width: calc(#{$mobile-wrapper} + #{$container-padding * 2});
  @include screen(tablet) {
    max-width: calc(#{$screen-tablet} + #{$container-padding * 2});
  }
  @include screen(tablet-2x) {
    max-width: calc(#{$screen-tablet-2x} + #{$container-padding * 2});
  }
  @include screen(laptop) {
    max-width: calc(#{$screen-laptop} + #{$container-padding * 2});
  }
}

.page-wrapper {
  padding-top: 60px;
  width: 100%;
  @include screen(laptop) {
    padding-top: 76px;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 100dvh;
}

.h1 {
  margin-bottom: 32px;
  @include font-h1;
  @include screen(tablet) {
    margin-bottom: 56px;
  }
}

.h2 {
  margin-bottom: 32px;
  @include font-h2;
  @include screen(tablet) {
    margin-bottom: 56px;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}