.header {
	position: fixed;
  top: 0;
	left: 0;
	width: 100%;
	padding: 19.5px 0;
	z-index: 101;
	transition: padding $transition-default, background-color $transition-default;
  @include screen(laptop) {
    padding: 20px 0;
  }
  &.scrolled {
    background-color: $white;
  }
  &.active {
    .header {
      &__logo {
        svg {
          path {
            fill: $white;
          }
        }
      }
      &__button {
        svg.active {
          path {
            stroke: $white;
          }
        }
      }
    }
  }
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    svg {
      min-width: 128px;
      max-width: 128px;
      height: 21px;
      width: 100%;
      path {
        transition: fill $transition-default;
      }
      @include screen(laptop) {
        min-width: 146px;
        max-width: 146px;
        width: 100%;
        height: 25px;
      }
    }
  }
  &__left {
    display: flex;
    align-items: center;
    gap: 80px;
    a {
      display: flex;
      align-items: center;
    }
    nav {
      display: none;
      @include screen(laptop) {
        display: block;
      }
      ul {
        display: flex;
        align-items: center;
        gap: 24px;
        li {
          display: flex;
          align-items: center;
          position: relative;
          a,
          span {
            cursor: pointer;
            padding: 7px 0;
            display: inline-block;
            @include font-text2;
            @include screen(laptop) {
            transition: color $transition-default;
              &:hover {
                color: $main;
              }
            }
          }
          svg {
            cursor: pointer;
            margin-left: 4px;
            transition: transform $transition-default;
          }
          &.drop__trigger {
            display: flex;
            align-items: center;
            .header__dropdown {
              position: absolute;
              top: 36px;
              left: 0;
              visibility: hidden;
              background-color: $white;
              box-shadow: 0 4px 24px 3px rgba(65,74,120,0.1);
              border-radius: 8px;
              padding: 12px 24px;
              z-index: -1;
              opacity: 0;
              a {
                white-space: nowrap;
                @include font-text4;
                padding: 6px 0;
                @include screen(laptop) {
                  transition: color $transition-default;
                  &:hover {
                    color: $main;
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
            @include screen(laptop) {
              &:hover {
                & > a,
                & > span {
                  color: $main;
                }
                svg {
                  transform: rotate(180deg);
                  path {
                    stroke: $main;
                  }
                }
                .header__dropdown {
                  transition: $transition-default;
                  opacity: 1;
                  z-index: 10;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }
  &__right {
		display: none;
    @include screen(laptop) {
      display: flex;
      align-items: center;
    }
		a {
      border-bottom: 1px solid $text;
      @include font-text2;
      @include screen(laptop) {
        transition: color $transition-default, border-color $transition-default;
        &:hover {
          color: $main;
          border-color: $main;
        }
      }
		}
	}
  &__button {
    display: block;
    position: absolute;
    right: 0;
    transition: $transition-default;
    cursor: pointer;
    padding: 10px;
    @include screen(laptop) {
      display: none;
    }
    svg {
      path {
        transition: transform $transition-default, stroke $transition-default, opacity $transition-default;
      }
      @include screen(laptop) {
        &:hover {
          path {
            stroke: $main;
          }
        }
      }
      &.active {
        path {
          stroke: $main;
          &:nth-child(1) {
            opacity: 0;
          }
          &:nth-child(2) {
            transform: rotate(45deg) translateX(5px) translateY(-7px);
          }
          &:nth-child(3) {
            transform: rotate(-45deg) translateX(-12px) translateY(0px);
          }
        }
      }
    }
  }
}

.mobMenu {
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	position: fixed;
	background-color: $main;
	height: 100dvh;
	width: 100%;
	left: 0;
	top: 0;
	padding-top: 120px;
	transition: $transition-default;
  @include screen(laptop) {
    display: none;
  }
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		align-content: space-between;
  }
	&__links {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 24px;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    a,
    span {
      cursor: pointer;
      color: $white;
      border-bottom: 1px solid transparent;
      @include font-text-semibold;
      @include screen(laptop) {
        transition: border-color $transition-default;
        &:hover {
          border-color: $white;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    svg {
      margin-left: 5px;
      cursor: pointer;
      transition: transform $transition-default;
      &.active {
        transform: rotate(180deg);
      }
    }
    .dropdown {
      flex-basis: 100%;
      display: none;
      .dropdown__link {
        text-align: center;
        flex-basis: 100%;
        margin-bottom: 18px;
        &:nth-child(1) {
          margin-top: 18px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $white;
          opacity: 0.8;
          @include font-p1;
        }
      }
    }
  }
  &.active {
    z-index: 100;
    opacity: 1;
    visibility: visible;
  }
}