.format {
  padding: 60px 0;
  &__items {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    counter-reset: items;
    gap: 16px;
    @include screen(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 32px;
    }
  }
  &__item {
    border-radius: 20px;
    border: 1px solid $gray;
    padding: 32px 40px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__title {
    margin-top: 24px;
    @include font-text1;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    justify-content: space-between;
  }
}