.services {
  padding: 60px 0;
  @include screen(laptop) {
    padding: 120px 0 60px;
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    counter-reset: items;
    gap: 16px;
    @include screen(tablet) {
      gap: 32px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  &__item {
    border-radius: 20px;
    background-color: $bg-light;
    padding: 32px 40px;
    margin-top: 32px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    justify-content: flex-end;
    position: relative;
    &:before {
      content: "0" counter(items);
      counter-increment: items;
      position: absolute;
      width: 42px;
      height: 42px;
      border: 1px solid $main;
      border-radius: 50%;
      top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 32px;
      color: $main;
      @include font-text2;
    }
    h3 {
      margin-top: 82px;
      @include font-text1;
    }
  }
}