$transition-default: 0.3s;
$transition-slow: 0.8s;

$container-padding: 20px;

$screen-laptop: 1140px;
$screen-tablet: 746px;
$screen-mobile: 640px;

$mobile-wrapper: 335px;

$white: #ffffff;
$black: #000000;

$main: #0475C7;
$main-hover: #3CA0E8;
$text: #333333;
$link-hover: #0475C7;
$text-secondary: #949494;
$bg: #1F2022;
$bg-2: #282A2C;
$bg-light: #F9F9F9;
$gray: #DDDDDD;
$gray-600: #626262;
$light-main: #E5F4FF;