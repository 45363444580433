.prices {
  padding: 60px 0;
  background-color: $bg-light;
  @include screen(laptop) {
    padding: 120px 0;
  }
  &__title {
    span {
      color: $main;
    }
  }
  &-offset-small {
    padding: 60px 0 0;
    @include screen(laptop) {
      padding: 100px 0 0;
    }
  }
  &-white {
    background-color: $white;
  }
  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 500px;
  }
  &__item {
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid $text-secondary;
    p {
      @include font-p0;
    }
    span {
      margin-left: 4px;
      @include font-text1;
      @include screen(tablet) {
        @include font-text-semibold;
      }
    }
  }
}