.about {
  background-color: $bg;
  padding: 60px 0;
  @include screen(laptop) {
    padding: 120px 0;
  }
  &-offset-small {
    padding: 60px 0;
    @include screen(laptop) {
      padding: 100px 0 60px;
    }
  }
  &-white {
    background-color: $white;
    .about {
      &__title {
        color: $text;
      }
      &__text {
        color: $text;
      }
      &__block {
        outline-color: $white;
        background-color: $bg-light;
        span,
        p {
          color: $text;
        }
        &:nth-last-child(2) {
          @include screen(tablet) {
            margin-left: 0;
          }
          @include screen(laptop) {
            margin-left: -32px;
          }
        }
        &:nth-last-child(3) {
          margin-left: auto;
          @include screen(tablet) {
            margin-left: -24px;
            margin-top: 0;
          }
        }
      }
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    @include screen(laptop) {
      flex-direction: row;
    }
  }
  &__title {
    color: $white;
  }
  &__text {
    color: $white;
    max-width: 510px;
    @include font-p3;
    @include screen(laptop) {
      @include font-p2;
    }
    br {
      display: none;
      @include screen(laptop) {
        display: block;
      }
    }
  }
  &__blocks {
    display: flex;
    margin-top: 64px;
    flex-wrap: wrap;
    padding: 0 10px;
    @include screen(tablet) {
      padding: 0;
      justify-content: center;
    }
    @include screen(laptop) {
      margin-top: 100px;
      flex-wrap: nowrap;
    }
  }
  &__block {
    min-width: 180px;
    min-height: 180px;
    border-radius: 50%;
    background-color: $bg-2;
    outline: 8px solid $bg;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $white;
    gap: 6px;
    margin-top: -70px;
    &:nth-child(2n) {
      margin-left: auto;
      @include screen(tablet) {
        margin-left: -32px;
      }
    }
    @include screen(tablet) {
      margin-top: 0;
      margin-left: -32px;
    }
    @include screen(laptop) {
      margin-left: -24px;
      min-width: 210px;
      min-height: 210px;
    }
    span {
      font-size: 50px;
      line-height: 100%;
      font-weight: 700;
    }
    p {
      text-align: center;
      @include font-text3;
    }
    &:first-child {
      margin-top: 0;
      margin-left: 0;
    }
    &-blue {
      background-color: $main;
      flex-direction: row;
      gap: 6px;
      margin-left: auto;
      @include screen(tablet) {
        margin-left: -32px;
      }
      @include screen(laptop) {
        transition: background-color $transition-default;
        &:hover {
          background-color: $main-hover;
        }
      }
    }
    &-hidden {
      display: none;
      @include screen(tablet) {
        display: flex;
      }
      @include screen(laptop) {
        display: none;
      }
    }
    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      @include screen(tablet) {
        margin-top: -24px;
      }
      @include screen(laptop) {
        margin-top: 0;
      }
    }
    &:nth-last-child(3) {
      margin-left: 0;
      @include screen(laptop) {
        margin-left: -24px;
      }
    }
  }
}