.offers {
  padding: 60px 0;
  @include screen(laptop) {
    padding: 120px 0 60px;
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    counter-reset: items;
    gap: 16px;
    @include screen(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 32px;
    }
    @include screen(laptop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  &__item {
    border-radius: 20px;
    background-color: $bg-light;
    padding: 32px 40px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__title {
    margin-top: 24px;
    @include font-text1;
  }
  &__techs {
    color: $text-secondary;
    @include font-p0;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    justify-content: space-between;
  }
}