.projects {
  padding: 60px 0;
  background-color: $bg-light;
  @include screen(laptop) {
    padding: 120px 0;
  }
  &-offset-small {
    padding: 60px 0 0;
    @include screen(laptop) {
      padding: 100px 0 0;
    }
  }
  &-white {
    background-color: $white;
  }
  &__title {
    span {
      color: $main;
    }
  }
  &__items {
    margin-top: 24px;
    width: 100%;
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid $gray;
    padding: 24px 0;
    @include screen(tablet) {
    }
    @include screen(laptop) {
      display: grid;
      gap: 0;
      padding: 40px 0;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      align-items: flex-start;
    }
  }
  &__links {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }
  &__link {
    @include font-p0;
    max-width: 440px;
    span {
      border-bottom: 1px solid $text;
      @include screen(laptop) {
        transition: color $transition-default, border-color $transition-default;
      }
    }
    &:not(.projects__link-nolink) {
      @include screen(laptop) {
        &:hover {
          span {
            color: $main;
            border-color: $main;
          }
          svg {
            path {
              stroke: $main;
            }
          }
        }
      }
    }
    svg {
      display: inline;
      min-width: 22px;
      min-height: 22px;
      path {
        @include screen(laptop) {
          transition: stroke $transition-default;
        }
      }
    }
    &-nolink {
      span {
        border-bottom: none;
      }
      svg {
        opacity: 0.3;
      }
    }
  }
  &__heading {
    grid-column: span 4 / span 4;
    h3 {
      max-width: 310px;
      @include font-text;
    }
    span {
      color: $text-secondary;
      @include font-p0;
    }
  }
  &__links {
    grid-column: span 7 / span 7;
  }
  &__status {
    display: none;
    grid-column: span 1 / span 1;
    @include font-p2;
    span {
      border: 1px solid $text;
      border-radius: 80px;
      opacity: 0.6;
      padding: 8px 16px;
    }
    @include screen(laptop) {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__more {
    margin: 90px auto 0;
    min-width: 170px;
    min-height: 170px;
    max-width: 170px;
    max-height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 1px solid $text;
    border-radius: 50%;
    @include font-text2;
    svg {
      path {
        @include screen(laptop) {
          transition: stroke $transition-default;
        }
      }
    }
    @include screen(laptop) {
      transition: background-color $transition-default, border-color $transition-default, color $transition-default;
      &:hover {
        background-color: $main;
        border-color: $main;
        color: $white;
        svg {
          path {
            stroke: $white;
          }
        }
      }
    }
  }
}