.not-found {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 70px 0 0;
    @include screen(tablet) {
      padding: 100px 0 0;
    }
  }
  &__subtitle {
    @include font-text-semibold;
  }
  &__button {
    margin-top: 16px;
    border-radius: 6px;
    color: $white;
    padding: 8px 23px;
    text-align: center;
    background-color: $main;
    display: inline-block;
    @include font-text-medium-sm;
    @include screen(tablet) {
      padding: 8px 32px;
    }
    @include screen(laptop) {
      transition: background-color $transition-default;
      &:hover {
        background-color: $main-hover;
      }
    }
  }
}