.hero {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 70px 0 0;
    @include screen(tablet) {
      justify-content: center;
      min-height: calc(100dvh - 212px);
    }
  }
  &__title {
    margin-bottom: 24px;
    span {
      color: $main;
    }
    @include font-h1;
    br {
      @include screen(tablet) {
        display: none;
      }
    }
  }
  &__content {
    max-width: 930px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }
  &__tag {
    @include font-text2;
  }
  &__clients {
    padding-bottom: 16px;
    margin-top: 64px;
    width: 100%;
    @include screen(tablet) {
      margin-top: 0;
    }
    .swiper-wrapper {
      -webkit-transition-timing-function: linear;
      -o-transition-timing-function: linear;
      transition-timing-function: linear;
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 120px;
      max-height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}