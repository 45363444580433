.lab {
  padding: 60px 0;
  @include screen(laptop) {
    padding: 120px 0 60px;
  }
  &__wrapper  {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: flex-start;
    gap: 48px;
    @include screen(laptop) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  &__content  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include screen(laptop) {
      position: sticky;
      top: 100px;
    }
  }
  &__title {
    @include screen(laptop) {
      margin-bottom: 16px;
    }
  }
  &__tags {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }
  &__tag {
    border: 1px solid $gray;
    border-radius: 16px;
    padding: 8px 16px;
    @include font-p2;
  }
  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 56px;
  }
  &__text {
    margin-bottom: 32px;
    @include font-p3;
    @include screen(laptop) {
      margin-bottom: 56px;
      @include font-p2;
    }
    span {
      font-weight: 700;
    }
  }
  &__subtitle {
    margin-bottom: 24px;
    @include font-p1;
  }
  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
    border-radius: 20px;
    background-color: $bg-light;
    padding: 24px 32px;
    h3 {
      @include font-p1;
    }
    p {
      @include font-text4;
    }
  }
  &__button {
    border-radius: 6px;
    color: $white;
    padding: 8px 23px;
    text-align: center;
    background-color: $main;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    @include font-text-medium-sm;
    @include screen(tablet) {
      padding: 8px 32px;
    }
    @include screen(laptop) {
      transform: translateX(0);
      left: 0;
      position: static;
      transition: background-color $transition-default;
      &:hover {
        background-color: $main-hover;
      }
    }
  }
  &__partners {
    max-width: 530px;
    margin: 0 auto;
  }
  &__images {
    display: flex;
    align-items: center;
    gap: 48px;
    flex-wrap: wrap;
    margin: 0 auto 32px;
    @include screen(laptop) {
      margin: 0 auto 56px;
    }
    @include screen(laptop) {
      flex-wrap: nowrap;
    }
  }
}