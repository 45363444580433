.service-hero {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 70px 0 0;
    @include screen(tablet) {
      justify-content: center;
      min-height: calc(100dvh - 212px);
    }
  }
  &__title {
    margin-bottom: 24px;
    br {
      display: none;
      @include screen(laptop) {
        display: block;
      }
    }
  }
  &__text {
    max-width: 700px;
    @include font-p0;
    br {
      display: none;
      @include screen(laptop) {
        display: block;
      }
    }
  }
}