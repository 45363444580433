.awards {
  padding: 60px 0;
  @include screen(tablet) {
    padding: 60px 0 120px;
  }
  &__items {
    width: 100%;
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-bottom: 1px solid $gray;
    padding: 24px 0;
    @include screen(laptop) {
      display: grid;
      gap: 0;
      padding: 24px;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      align-items: center;
    }
  }
  &__source {
    grid-column: span 3 / span 3;
    display: flex;
    align-items: center;
    gap: 16px;
    img {
      max-height: 14px;
      max-width: 14px;
      min-height: 14px;
      min-width: 14px;
    }
    span {
      @include font-text1;
    }
  }
  &__prizes {
    display: flex;
    align-items: center;
    gap: 8px;
    grid-column: span 2 / span 2;
  }
  &__prize {
    max-height: 48px;
    max-width: 48px;
    min-height: 48px;
    min-width: 48px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__title {
    order: 4;
    grid-column: span 5 / span 5;
    @include font-p2;
    @include screen(laptop) {
      order: inherit;
      transition: color $transition-default;
      &:hover {
        color: $main;
      }
    }
  }
  &__year {
    grid-column: span 2 / span 2;
    @include font-text1;
    @include screen(laptop) {
      text-align: right;
    }
  }
}