.footer {
  width: 100%;
  padding: 50px 0;
  @include screen(laptop) {
    padding: 120px 0;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    @include screen(tablet) {
      flex-direction: row;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    span {
      @include font-text2;
    }
  }
  &__subtitle {
    color: $text-secondary;
    @include font-p2;
  }
  &__link {
    @include font-text2;
    br {
      display: none;
      @include screen(tablet) {
        display: block;
      }
    }
    &-underline {
      border-bottom: 1px solid $text;
      @include screen(laptop) {
        transition: border-color $transition-default;
        &:hover {
          border-color: $main;
        }
      }
    }
    @include screen(laptop) {
      transition: color $transition-default;
      &:hover {
        color: $main;
      }
    }
  }
}