.technologies {
  padding: 60px 0;
  @include screen(laptop) {
    padding: 60px 0 120px;
  }
  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    @include screen(laptop) {
      gap: 48px;
    }
  }
  &__item {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    @include screen(laptop) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      flex-direction: row;
      justify-content: space-between;
    }
    h3 {
      @include font-text-semibold;
    }
  }
  &__icons {
    grid-column-start: 3;
    max-width: 596px;
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    margin-top: 16px;
    @include screen(laptop) {
      margin-top: 0;
    }
  }
  &__icon {
    min-width: 68px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    img {
      max-height: 24px;
      min-height: 24px;
    }
    span {
      color: $gray-600;
      @include font-text2-sm;
    }
  }
}