.categories {
  padding: 60px 0;
  &__items {
    display: grid;
    border: 1px solid $gray;
    border-radius: 20px;
    overflow: hidden;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @include screen(laptop) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
  &__item {
    padding: 48px 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 24px;
    border-bottom: 1px solid $gray;
    &:last-child {
      border-bottom: none;
    }
    @include screen(laptop) {
      border-bottom: none;
      transition: background-color $transition-default;
      &:hover {
        background-color: $main;
        h3,
        p {
          color: $white;
        }
      }
    }
    &-col6 {
      @include screen(laptop) {
        grid-column: span 6 / span 6;
      }
    }
    &-col4 {
      @include screen(laptop) {
        grid-column: span 4 / span 4;
      }
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4) {
      @include screen(laptop) {
        border-right: 1px solid $gray;
      }
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      @include screen(laptop) {
        border-bottom: 1px solid $gray;
      }
    }
    h3 {
      @include screen(laptop) {
        transition: color $transition-default;
      }
      @include font-text-medium;
    }
    p {
      @include screen(laptop) {
        transition: color $transition-default;
      }
      @include font-text4;
    }
  }
}