.competencies {
  padding: 60px 0;
  @include screen(laptop) {
    padding: 120px 0 60px;
  }
  &__wrapper  {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: flex-start;
    gap: 48px;
    @include screen(laptop) {
      gap: 80px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  &__content  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include screen(laptop) {
      position: sticky;
      top: 100px;
    }
  }
  &__title {
    @include screen(laptop) {
      margin-bottom: 16px;
    }
  }
  &__tags {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }
  &__tag {
    border: 1px solid $gray;
    border-radius: 16px;
    padding: 8px 16px;
    @include font-p2;
  }
  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
    border-radius: 20px;
    background-color: $light-main;
    padding: 40px;
    h3 {
      @include font-h3;
    }
    p {
      @include font-text4;
    }
  }
}