.contacts {
  padding: 60px 0 0;
  @include screen(laptop) {
    padding: 100px 0 0;
  }
  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 48px;
    @include screen(laptop) {
      flex-direction: row;
    }
  }
  &__email {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    a {
      color: $main;
      border-bottom: 1px solid $main;
      @include font-h3;
      font-weight: 600;
      line-height: 36px;
    }
    span {
      color: $text-secondary;
      @include font-p0;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    p {
      color: $text-secondary;
      @include font-p0;
    }
    a,
    span {
      @include font-text;
    }
    a {
      @include screen(laptop) {
        transition: color $transition-default;
        &:hover {
          color: $main;
        }
      }
    }
  }
}