.partners {
  padding: 60px 0;
  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  &__text {
    max-width: 747px;
    @include font-p3;
    @include screen(laptop) {
      @include font-p2;
    }
    br {
      display: none;
      @include screen(laptop) {
        display: block;
      }
    }
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 64px;
    gap: 64px;
    align-items: flex-start;
    @include screen(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 80px;
    }
    @include screen(laptop) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    img {
      min-width: 80px;
      min-height: 80px;
      max-width: 160px;
      @include screen(tablet) {
        min-width: 112px;
        min-height: 112px;
        max-width: 200px;
      }
    }
    span {
      text-align: center;
      color: $text-secondary;
      @include font-p0;
    }
  }
}